<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import ButtonBack from '@/components-deprecated/ButtonBack.vue';
import { isWebChatbotEnabled, isWebChatbotSettingsPageEnabled } from '@/lib/feature-flag';

const store = useStore();
const isLoading = ref(false);

// Since this page can be navigated to directly, we must check if it should show:
const showPage = ref(false);
onMounted(async () => {
    try {
        const webChatbotEnabled = await isWebChatbotEnabled(store.state.user.schoolRef.id);
        const settingsPageEnabled = webChatbotEnabled
            ? await isWebChatbotSettingsPageEnabled(store.state.user.schoolRef.id)
            : false;

        if (webChatbotEnabled && settingsPageEnabled) {
            showPage.value = true;
        }
    } finally {
        isLoading.value = false;
    }
});
</script>

<template>
    <div class="container">
        <ButtonBack />

        <LoadingSpinner v-if="isLoading" />
        <div v-if="showPage">
            <div class="manage-web-chatbot">
                <h1 class="h2">Web Chatbot Settings</h1>
                <p>
                    Below are the web chatbot configuration settings. This page is "view only" so if
                    you need to make edits, reach out to your EdSights point of contact.
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>
